// Variable overrides

$grid-gutter-width: 16px;
$sidebar-nav-dropdown-bg: #25b3eb;
$sidebar-bg: white;
$sidebar-nav-link-color: gray;
$sidebar-nav-title-color: #232323;
$sidebar-nav-link-hover-bg: #25b3eb;
$sidebar-nav-link-active-color: #25b3eb;
$sidebar-nav-link-active-icon-color: #25b3eb;
$sidebar-minimizer-bg: #25b3eb;
$sidebar-minimizer-hover-bg: #25b3eb;
$sidebar-minimizer-indicator-color: #fff;

$navbar-bg: #d7f1ff;

$navbar-color: white;
$navbar-hover-color: white;
