// Here you can add other styles
.aside-toggler {
  i {
    color: white;
    font-size: 1.4rem;
  }
  .badge {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -16px;
    margin-left: 0;
    font-size: 0.6em;
  }
}
.nav-item {
  i {
    font-size: 1.4rem;
  }
}

.card-title {
  text-transform: uppercase;
}

.sidebar {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  .nav-link {
    padding-left: 2rem !important;
    .nav-icon {
      display: none !important;
    }
  }
}
