// If you want to override variables do it here
@import "variables";
// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";
// If you want to add something do it here
@import "custom";
// ie fixes
@import "ie-fix";

body {
  font-family: "Open Sans";
}

#main-wrap {
  position: relative;
  min-height: 400px;
}
.main .container-fluid {
  padding: 0 16px;
}
.main .container-fluid {
  @include media-breakpoint-down("md") {
    padding: 0 10px;
  }
}

.DateRangePicker {
  @include media-breakpoint-down("lg") {
    width: 100%;
  }

  &Input {
    @include media-breakpoint-down("lg") {
      width: 100%;
    }
  }
}
.date-period {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
}
#user-menu-dropdown {
  padding-right: 16px;
}
.app-header {
  border: 0;
}
.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='100px' height='100px' viewBox='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon/menu@1x%3C/title%3E%3Cg id='icon/menu' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='Group-10' transform='translate(12.999900, 19.000000)' fill='%230B2641'%3E%3Cg id='Group-3'%3E%3Cpath d='M67.826,11.421 L5.711,11.421 C2.562,11.421 0,8.859 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.859 70.975,11.421 67.826,11.421 L67.826,11.421 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-6' transform='translate(0.000000, 25.290000)'%3E%3Cpath d='M67.826,11.421 L5.711,11.421 C2.562,11.421 0,8.859 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.859 70.975,11.421 67.826,11.421 L67.826,11.421 Z' id='Fill-4'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-9' transform='translate(0.000000, 50.823200)'%3E%3Cpath d='M67.826,11.422 L5.711,11.422 C2.562,11.422 0,8.86 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.86 70.975,11.422 67.826,11.422 L67.826,11.422 Z' id='Fill-7'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='100px' height='100px' viewBox='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon/menu@1x%3C/title%3E%3Cg id='icon/menu' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='Group-10' transform='translate(12.999900, 19.000000)' fill='%230B2641'%3E%3Cg id='Group-3'%3E%3Cpath d='M67.826,11.421 L5.711,11.421 C2.562,11.421 0,8.859 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.859 70.975,11.421 67.826,11.421 L67.826,11.421 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-6' transform='translate(0.000000, 25.290000)'%3E%3Cpath d='M67.826,11.421 L5.711,11.421 C2.562,11.421 0,8.859 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.859 70.975,11.421 67.826,11.421 L67.826,11.421 Z' id='Fill-4'%3E%3C/path%3E%3C/g%3E%3Cg id='Group-9' transform='translate(0.000000, 50.823200)'%3E%3Cpath d='M67.826,11.422 L5.711,11.422 C2.562,11.422 0,8.86 0,5.711 C0,2.562 2.562,0 5.711,0 L67.826,0 C70.975,0 73.536,2.562 73.536,5.711 C73.536,8.86 70.975,11.422 67.826,11.422 L67.826,11.422 Z' id='Fill-7'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #25b3eb;
  border-color: #25b3eb;
}
.btn-outline-primary {
  border-color: #25b3eb;
  color: #25b3eb;
}
.btn-primary {
  color: #fff;
  background-color: #25b3eb;
  border-color: #25b3eb;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #0b2641 !important;
  border: 1px double #0b2641 !important;
  color: #fff;
}
.CalendarDay__selected_span {
  color: #fff;
  background-color: #25b3eb !important;
  border-color: #25b3eb !important;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #eee !important;
}
.card {
  border-radius: 10px;
  border: 0px;
}
.card-body {
  > i {
    border-radius: 200px;
  }
}
.card-header:first-child {
  border-radius: 10px;
}
.card-header {
  background: white;
  border: 0;
  border-top-left-radius: 10px;
  font-size: 20px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

.sidebar-minimized {
  .sidebar {
    .sidebar-minimizer {
      background-color: $sidebar-minimizer-bg;
    }
  }
}

.sidebar {
  .sidebar-header {
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    text-align: left;
    background: none;
    color: $gray-900;
  }
}
.sidebar .nav-link.active {
  background: #d7f1ff;
  border-right: 4px solid #25b3eb;
  color: #0b2641;
}
.sidebar .nav-link:hover {
  background: #d7f1ff;
  border-right: 4px solid #25b3eb;
  color: #0b2641;
}

.check-swsm + .label-swsm {
  text-decoration: line-through;
  color: #6a6a6a;
}

.check-swsm:checked + .label-swsm {
  text-decoration: none;
  color: inherit;
}
